<template>
  <v-col cols="12" v-bind="$attrs" v-on="$listeners">
    <v-card outlined>
      <v-row v-if="loading" class="py-4 px-8">
        <v-col cols="12" class="text-center">
          <v-progress-circular indeterminate color="primary" />
        </v-col>
      </v-row>
      <template v-else>
        <v-row class="py-4 px-8">
          <v-col>
            <v-tabs v-model="tab">
              <v-tab
                class="pa-1 mr-2 text-capitalize"
                v-for="tab in tabs"
                :key="tab"
                :disabled="tab == 'Pop up Quiz' && !isSaved"
              >
                <span> {{ tab }}</span>
              </v-tab>
            </v-tabs>
            <div class="pt-8">
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <div class="font-weight-bold">Aktivitas {{ seq }}</div>
                  <v-row>
                    <v-col cols="12">
                      <div class="font-weight-medium mb-2">
                        Judul Aktivitas
                      </div>
                      <v-text-field
                        outlined
                        placeholder="Masukkan judul aktivitas"
                        v-model="activity.title"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <div class="font-weight-medium">
                        Jenis Aktivitas
                      </div>
                      <v-select
                        :disabled="$route.name == 'activity-edit'"
                        :items="types"
                        item-text="text"
                        item-value="value"
                        v-model="activity.type"
                      ></v-select>
                    </v-col>
                    <v-col v-if="isAbleToClone" cols="12">
                      <v-checkbox v-model="activity.is_clone">
                        <span slot="label" class="color--text">Clone pre test</span>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <div class="font-weight-bold mt-2">
                        Diskusi Aktivitas
                      </div>
                      <v-select
                        :items="discussions"
                        v-model="activity.settings.discussion"
                        item-text="text"
                        item-value="value"
                        ></v-select>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <setting-pre v-if="QuestionSetting" v-model="activity.settings" />
                  <setting-quiz v-if="Question2Setting" v-model="activity.settings" />
                  <setting-post v-if="Question3Setting" v-model="activity.settings" />
                  <setting-exam v-if="Question4Setting" v-model="activity.settings" />
                  <setting-video v-if="VideoSetting" v-model="activity.settings" settingTitle="Pengaturan Video" settingLabel="Estimasi Durasi Video (Menit)" />
                  <setting-diskusi v-if="DiskusiSetting" v-model="activity.settings" settingTitle="Pengaturan Estimasi Durasi" settingLabel="Estimasi Durasi (Menit)" />
                  <setting-practice v-if="PracticeSetting" v-model="activity.settings" settingTitle="Pengaturan Estimasi Durasi" settingLabel="Estimasi Durasi (Menit)" />
                  <setting-evaluation v-if="EvaluationSetting" v-model="activity.settings" settingTitle="Pengaturan Estimasi Durasi" settingLabel="Estimasi Durasi (Menit)" />
                  <setting-book v-if="BookSetting" v-model="activity.settings" settingTitle="Pengaturan Estimasi Durasi" settingLabel="Estimasi Durasi (Menit)" />
                  <setting-eprak v-if="EprakSetting" v-model="activity.settings" />
                  <!-- <setting-general v-if="GeneralSetting" v-model="activity.settings" settingTitle="Pengaturan Estimasi Durasi" settingLabel="Estimasi Durasi (Menit)" /> -->
                  <!-- <setting-learning v-if="LearningSetting" v-model="activity.settings" settingTitle="Pengaturan Video" settingLabel="Estimasi Durasi Video (Menit)" /> -->
                </v-tab-item>
                <v-tab-item>
                  <content-pre v-if="QuestionSetting" ref="question" v-model="activity.theory" />
                  <content-quiz v-if="Question2Setting" ref="question" v-model="activity.theory" />
                  <content-post v-if="Question3Setting" ref="question" v-model="activity.theory" />
                  <content-exam v-if="Question4Setting" v-model="activity.theory" />
                  <content-video v-if="VideoSetting" ref="resourcesvideo" v-model="activity.theory" />
                  <content-practice v-if="PracticeSetting" ref="resourcespractice" v-model="activity.theory" />
                  <content-book v-if="BookSetting" ref="resourcesbook" v-model="activity.theory" />
                  <content-diskusi v-if="DiskusiSetting" v-model="activity.theory" />
                  <content-evaluation v-if="EvaluationSetting" v-model="activity.theory" />
                  <content-eprak v-if="EprakSetting" v-model="activity.theory" />
                  <!-- <content-general v-if="GeneralSetting" v-model="activity.theory" /> -->
                  <!-- <content-learning v-if="LearningSetting" v-model="activity.theory" /> -->
                </v-tab-item>
                <v-tab-item v-if="VideoSetting">
                  <pop-quiz-setting  
                    ref="gameplay" 
                    :gameplayData="activity.quiz || {}" 
                    v-model="activity.quiz"  
                    @click:showDetail="$emit('click:showDetail', $event)" />
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-col>
        </v-row>
        <v-progress-linear v-if="submiting" :value="progress"></v-progress-linear>
        <v-card-actions class="background" v-show="tab != 3">
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="submiting" outlined @click="$emit('click:cancel')">Batal</v-btn>
          <v-btn color="primary" :loading="submiting" @click="handleSubmit">Simpan</v-btn>
        </v-card-actions>
      </template>
    </v-card>

    <v-snackbar top v-model="snackbar.state" :timeout="3000" color="primary" outlined>
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn small icon color="error" v-bind="attrs" @click="snackbar.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-col>
</template>

<script>
import SettingPractice from "./Settings/Video";
import SettingBook from "./Settings/Video";
import SettingPre from "./Settings/Question";
import SettingQuiz from "./Settings/Question";
import SettingExam from "./Settings/Exam";
import SettingPost from "./Settings/Question";
import SettingVideo from "./Settings/Video";
import SettingDiskusi from "./Settings/Video";
import SettingEvaluation from "./Settings/Video";
import SettingEprak from "./Settings/EvaluasiPrak";
// import SettingGeneral from "./Settings/Video";
// import SettingLearning from "./Settings/Video";
import ContentPre from "./Contents/Question";
import ContentQuiz from "./Contents/Question";
import ContentPost from "./Contents/Question";
import ContentExam from "./Contents/Exam";
import ContentVideo from "./Contents/Video";
import ContentEvaluation from "./Contents/Evaluation";
import ContentDiskusi from "./Contents/Disscus";
import ContentPractice from "./Contents/Practice";
import ContentBook from "./Contents/Practice";
import ContentEprak from "./Contents/EvaluationPrak";
// import ContentGeneral from "./Contents/General";
// import ContentLearning from "./Contents/Learning";
import PopQuizSetting from './Settings/PopQuiz'

export default {
  components: {
    PopQuizSetting,
    SettingPre,
    SettingPost,
    SettingQuiz,
    SettingExam,
    SettingVideo,
    SettingPractice,
    SettingBook,
    SettingEvaluation,
    SettingEprak,
    SettingDiskusi,
    // SettingGeneral,
    // SettingLearning,

    ContentPost,
    ContentPre,
    ContentQuiz,
    ContentExam,
    ContentVideo,
    ContentEvaluation,
    ContentDiskusi,
    ContentPractice,
    ContentBook,
    ContentEprak,
    // ContentGeneral,
    // ContentLearning,
  },
  props: {
    activity: { type: Object, required: true },
    seq: { type: [Number, String], required: true },
    submiting: Boolean,
  },
  data: () => ({
    tab: null,
    loading: null,
    clone: {},
    progress: null,
    snackbar: {state: false,text: ""},
    gameplays: {
      type: "",
      setting: {},
      gameplays: []
    }
  }),
  computed: {
    isSaved() {
      return !!this.$route.params.id;
    },
    tabs() {
      const tab =
        this.QuestionSetting || this.Question2Setting || this.Question3Setting || this.Question4Setting
          ? "Pertanyaan"
          : // : this.VideoSetting || this.PracticeSetting ||
            // ? "Konten"
            "Konten";
      const tabs = ["Detail", "Pengaturan", tab];
      if (this.VideoSetting) {
        tabs.push("Pop up Quiz")
      }
      return tabs;
    },
    types() {
      return [
        { value: "menonton_video", text: "Menonton Video" },
        // { value: "embed_youtube", text: "Video Pembelajaran" },
        { value: "pre_test", text: "Pre Test" },
        { value: "quiz", text: "Quiz" },
        { value: "post_test", text: "Post Test" },
        { value: "exam", text: "Exam" },
        { value: "praktek_mandiri", text: "Praktek Mandiri" },
        { value: "evaluasi_praktek_mandiri", text: "Evaluasi Praktek Mandiri" },
        { value: "membaca-e-book", text: "Membaca E-Book" },
        { value: "berdiskusi_daring", text: "Berdiskusi Daring" },
        { value: "berdiskusi_luring", text: "Berdiskusi Luring" },
        { value: "evaluasi", text: "Evaluasi" },
        // { value: "general", text: "General" },
      ];
    },
    discussions() {
      return [
        { value: null, text: "Off" },
        { value: 'private', text: "Diskusi Private" },
        { value: 'public', text: "Diskusi Publik" },
      ]
    },
    isAbleToClone() {
      return (
        this.$route.name == "activity-create" &&
        ["post_test"].includes(this.activity.type) &&
        this.clone.type == "pre_test"
      );
    },
    VideoSetting() {
      return this.activity.type == this.types[0].value;
    },
    // LearningSetting() {
    //   return this.activity.type == this.types[1].value;
    // },
    QuestionSetting() {
      return this.activity.type == this.types[1].value;
    },
    Question2Setting() {
      return this.activity.type == this.types[2].value;
    },
    Question3Setting() {
      return this.activity.type == this.types[3].value;
    },
    Question4Setting() {
      return this.activity.type == this.types[4].value;
    },
    PracticeSetting() {
      return this.activity.type == this.types[5].value;
    },
    EprakSetting() {
      return this.activity.type == this.types[6].value;
    },
    BookSetting() {
      return this.activity.type == this.types[7].value;
    },
    DiskusiSetting() {
      return [this.types[8].value, this.types[9].value].includes(this.activity.type);
    },
    EvaluationSetting() {
      return this.activity.type == this.types[10].value;
    },
    // GeneralSetting() {
    //   return this.activity.type == this.types[12].value;
    // },
  },
  watch: {
    activity: {
      handler() {
        this.$emit("update:activity", this.activity);
      },
      deep: true,
    },
    // tab: {
    //   handler(value) {
    //     if(value == 3) {
    //       this.fetchGameplay()
    //     }
    //   },
    //   deep: true
    // }
  },
  async created() {
    this.loading = true;
    const cloneData = await this.axios
      .get(`/program/v1/company/check-available-prepost/${this.$route.params.program}`)
      .then((res) => res.data);
    this.clone = cloneData.data;
    if (["quiz", "pre_test", "post_test", "exam"].includes(this.activity.type)) {
      await this.$store.dispatch("packet/get", {
        program: this.$route.params.program,
        topic: this.$route.params.topic,
        activity: this.$route.params.id,
        params: { order_by: "created_at", dir: "asc" },
      });
    }
    this.loading = false;
  },
  methods: {
    async handleSubmit() {
      if (this.$refs.question) {
        await this.$refs.question
        .saveQuestions()
        .then(() => {
          this.$emit("click:submit", this.activity);
        })
        .catch((error) => {
          console.error(error);
          this.$emit("click:submit", error);
        });
      } else {
        // this.handleSubmitGameplay()
        if(this.activity.settings.max_upload_duration_evaluasi_praktek_mandiri == 0 || this.activity.settings.max_upload_duration_evaluasi_praktek_mandiri == null) {
          delete this.activity.settings.max_upload_duration_evaluasi_praktek_mandiri
        }
        this.$emit("click:submit", this.activity);
      }
    },
    async handleUpload(progress) {
      this.progress = (progress.loaded / progress.total) * 99;
    },
    normalizeSize(byte) {
      let units = "";
      if (byte / 1024 > 0.2) {
        byte = (byte / 1024).toFixed(2);
        units = "KB";
      }
      if (byte / 1024 > 0.2) {
        byte = (byte / 1024).toFixed(2);
        units = "MB";
      }
      if (byte / 1024 > 0.2) {
        byte = (byte / 1024).toFixed(2);
        units = "GB";
      }
      return `${byte}${units}`;
    },
  }
};
</script>
